* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

nav ul, ul.clean {
  margin-left: 0;
  list-style-type: none !important;
}

a, a:visited {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

a:focus {
  outline: none;
}

a::-moz-focus-inner {
  border: 0;
}

input, select, textarea {
  outline: 0;
}

input[type="button"] {
  outline: none;
}

input[type="button"]::-moz-focus-inner {
  border: 0;
}

button {
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
}

img {
  border: 0 !important;
  outline: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  text-align: center;
  font-size: 10px;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  width: 100%;
  height: 100%;
  line-height: 1.4em;
  position: relative;
}

header, footer, section, article, nav {
  display: block;
}

input, button, textarea {
  font-family: var(--ssr-font-family);
}

input {
  color: #fff;
}

input[type="number"] {
  margin: 0;
  -webkit-appearance: none !important;
}

input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

.btn {
  color: #fff;
  background-color: var(--chrome);
  text-transform: uppercase;
  font-family: var(--ss-font-family);
  border: 1px solid var(--white-opacity-01);
  webkit-transition: all .2s ease-in-out 0s;
  border-radius: 3px;
  transition: all .2s ease-in-out;
}

.btn:hover {
  cursor: pointer;
  background-color: #777;
}

.flat-btn {
  color: #fff;
  background-color: #0000;
  border: none;
}

.request-btn {
  color: #fff;
  background-color: var(--chrome);
  font-family: var(--ss-font-family);
  border: 1px solid var(--white-opacity-01);
  webkit-transition: all .2s ease-in-out 0s;
  border-radius: 3px;
  padding: 5px 10px;
  transition: all .2s ease-in-out;
}

.file-btn {
  color: var(--gray);
  height: 30px;
  margin: 0 auto 20px;
  position: relative;
}

.file-btn-native {
  z-index: 0;
  border: 1px solid var(--dark);
  height: 30px;
  margin-left: 32px;
  line-height: 27px;
  position: relative;
}

.file-btn-label {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--chrome);
  z-index: 1;
  border-radius: 3px;
  width: 120px;
  height: 30px;
  padding: 0 14px;
  font-family: sans-serif;
  font-size: 8px;
  line-height: 29px;
  display: inline-block;
  position: absolute;
}

.h1, .h2, .h3, h4, .h5, .t1, .t2, .t3, .t4, .t5, .t6, .a, .text, button, .btn, input, svg, a, .flat-btn, .settings-switch-bnt {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.a {
  color: #bbb;
  text-transform: uppercase;
  cursor: pointer;
}

.line {
  vertical-align: middle;
  background-color: var(--white-opacity-01);
  width: 100%;
  height: 1px;
  margin: 0 auto;
  display: block;
}

.cf:before, .cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.hide {
  visibility: hidden;
}

:root {
  --ssb-font-family: "Roboto", sans-serif;
  --ssr-font-family: "Roboto", sans-serif;
}

body {
  --black: #000;
  --white: #fff;
  --gray: #aaa;
  --green: #40ff70;
  --red: #ff495f;
  --yellow: #f8c73a;
  --dark: #28272d;
  --chrome: #35363a;
  --zone-red: #fe340b;
  --zone-orange: #ff663a;
  --zone-yellow: #f8c73a;
  --zone-green: #57c057;
  --zone-teal: #44a5ab;
  --zone-blue: #328aff;
  --zone-gray: #636468;
  --orange-logo: #fc4c02;
  --white-gray: #f7f7fa;
  --red-logo: #e50914;
  --black-opacity-01: #0000001a;
  --black-opacity-02: #0003;
  --white-opacity-01: #ffffff1a;
  --white-opacity-03: #ffffff4d;
}

.zone-one {
  background-color: var(--zone-gray);
}

.zone-two {
  background-color: var(--zone-blue);
}

.zone-three {
  background-color: var(--zone-teal);
}

.zone-four {
  background-color: var(--zone-green);
}

.zone-five {
  background-color: var(--zone-yellow);
}

.zone-six {
  background-color: var(--zone-orange);
}

.zone-seven {
  background-color: var(--zone-red);
}

.zone-one-color {
  color: var(--zone-gray);
}

.zone-two-color {
  color: var(--zone-blue);
}

.zone-three-color {
  color: var(--zone-teal);
}

.zone-four-color {
  color: var(--zone-green);
}

.zone-five-color {
  color: var(--zone-yellow);
}

.zone-six-color {
  color: var(--zone-orange);
}

.zone-seven-color {
  color: var(--zone-red);
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.gray {
  color: var(--gray);
}

.yellow {
  color: var(--yellow);
}

.red {
  color: var(--red);
}

.green {
  color: var(--green);
}

.black-bg {
  background-color: var(--black);
}

.white-bg {
  background-color: var(--white);
}

.gray-bg {
  background-color: var(--gray);
}

.yellow-bg {
  background-color: var(--yellow);
}

.red-bg {
  background-color: var(--red);
}

.green-bg {
  background-color: var(--green);
}

.gray-zone {
  background-color: var(--zone-gray);
}

.blue-zone {
  background-color: var(--zone-blue);
}

.green-zone {
  background-color: var(--zone-green);
}

.yellow-zone {
  background-color: var(--zone-yellow);
}

.orange-zone {
  background-color: var(--zone-orange);
}

.red-zone, .purple-zone {
  background-color: var(--zone-red);
}

.chrome-bg {
  background-color: var(--chrome);
}

.dark-bg {
  background-color: var(--dark);
}

body {
  color: var(--white);
  background-color: var(--dark);
}

.connections-header {
  background-color: var(--chrome);
}

#progress-active {
  background-color: var(--white-opacity-03);
}

.radio {
  fill: var(--white);
}

.mode.active {
  color: #fff;
}

.icon-btn .icon {
  fill: var(--gray);
}

.icon-btn .text {
  color: var(--gray);
}

.tab-btn.active .text {
  color: var(--white);
}

.tab-btn.active .icon-fill {
  fill: var(--white);
}

.control-icon {
  fill: #fff;
}

.wrapper {
  font-family: var(--ssr-font-family);
  align-content: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.page {
  width: 100%;
  min-height: 110vh;
  padding-bottom: 12em;
  transition: all .2s ease-in-out;
  display: none;
}

.page.active {
  display: block;
}

.page--header {
  width: 100%;
}

.page--heading {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 1.1em;
  line-height: 4em;
}

#workouts-page .page--header {
  margin-bottom: 40px;
}

#home-page {
  z-index: 1;
  min-height: 100vh;
  padding: 0;
}

#home-page-wrapper {
  grid-template-rows: 50px 2.5fr 1fr 1fr 98px 100px;
  grid-template-columns: 100%;
  height: 100vh;
  display: grid;
}

#compatibility--cont {
  background-color: var(--red);
  border-bottom: 1px solid var(--dark);
  z-index: 1;
  width: 100%;
  padding: 1em .5em;
  font-size: 1.4em;
  line-height: 1.5em;
}

#compatibility--cont a {
  color: var(--yellow);
}

.connections-header {
  flex-direction: row;
  grid-row: 1;
  place-content: center space-evenly;
  align-items: center;
  font-size: 1.1rem;
  display: flex;
}

.connection-switch, .protocol-switch {
  align-content: center;
  align-items: center;
  height: 3em;
  padding: 0 15px;
  display: flex;
}

.connection-switch:hover, .connection-icon-switch:hover {
  cursor: pointer;
}

.connection-switch--indicator {
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  margin: 0 1em 0 0;
}

.connection-switch--indicator.on, .connection-icon-switch--indicator.on {
  background-color: var(--green);
}

.connection-switch--indicator.loading, .connection-icon-switch--indicator.loading {
  background-color: var(--yellow);
}

.connection-switch--indicator.off, .connection-icon-switch--indicator.off {
  background-color: var(--red);
}

.connection-switch--label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1em;
  line-height: 1em;
}

.protocol-switch.on {
  color: var(--white);
}

.protocol-switch.loading, .protocol-switch.off {
  color: var(--gray);
}

.protocol-switch.on .protocol-btn--icon {
  fill: var(--white);
}

.protocol-switch.loading .protocol-btn--icon, .protocol-switch.off .protocol-btn--icon {
  fill: var(--gray);
}

.protocol-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.protocol-btn--icon {
  fill: var(--white);
  width: var(--size--icon--global);
  height: var(--size--icon--global);
}

.protocol-btn--label {
  margin-left: 10px;
  font-weight: bold;
}

.data-tiles {
  --data-tile--heading-font-size: 1em;
  --data-tile--value-font-size: 3.2em;
  --data-tile--unit-font-size: 1em;
  --data-tile-small--heading-font-size: 1em;
  --data-tile-small--value-font-size: 3em;
  --data-tile-small--unit-font-size: .6em;
  --data-tile--heading-margin: 0 auto 1em;
  grid-row: 2;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(6, 1fr);
  place-items: center;
  font-size: 1rem;
  display: grid;
  position: relative;
}

#data-tile--power-avg {
  grid-area: 3 / 1 / 3 / 4;
  display: none;
}

#data-tile--power {
  grid-area: 1 / 1 / 1 / 3;
}

#data-tile--interval-time {
  grid-area: 1 / 3 / 1 / 5;
}

#data-tile--heart-rate {
  grid-area: 1 / 5 / 1 / 7;
}

#data-tile--slope {
  grid-area: 3 / 4 / 3 / 7;
  display: none;
}

#data-tile--target {
  cursor: pointer;
  grid-area: 2 / 1 / 2 / 3;
}

#data-tile--elapsed-time {
  grid-area: 2 / 3 / 2 / 5;
}

#data-tile--cadence {
  grid-area: 2 / 5 / 2 / 7;
}

#data-tile--speed {
  grid-area: 3 / 1 / 3 / 4;
  display: none;
}

#data-tile--distance {
  grid-area: 3 / 4 / 3 / 7;
  display: none;
}

#data-tile--smo2 {
  grid-area: 3 / 1 / 3 / 4;
  display: none;
}

#data-tile--thb {
  grid-area: 3 / 4 / 3 / 7;
  display: none;
}

#data-tile--power-avg.active, #data-tile--slope.active, #data-tile--speed.active, #data-tile--distance.active, #data-tile--smo2.active, #data-tile--thb.active {
  display: block;
}

.data-tile--switch--group {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2em;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.data-tile--switch {
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 2em;
  display: flex;
}

.data-tile--switch .data-tile--switch--icon {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: block;
}

.data-tile--switch.active .data-tile--switch--icon {
  background-color: #fff;
  border: 1px solid #fff;
}

.data-tile-small--unit {
  display: none;
}

.data-tile--heading {
  font-size: var(--data-tile--heading-font-size);
  margin: var(--data-tile--heading-margin);
  font-family: var(--ssr-font-family);
  text-transform: uppercase;
}

.data-tile--value {
  font-size: var(--data-tile--value-font-size);
  line-height: 1em;
  font-family: var(--ssb-font-family);
  font-weight: bold;
}

.data-tile-small--heading {
  margin: var(--data-tile--heading-margin);
  font-size: var(--data-tile-small--heading-font-size);
  text-transform: uppercase;
}

.data-tile-small--heading sub {
  text-transform: lowercase;
}

.data-tile-small--value {
  font-size: var(--data-tile-small--value-font-size);
  font-weight: bold;
  line-height: 1em;
}

.data-tile-small--unit {
  font-size: var(--data-tile-small--unit-font-size);
  font-weight: bold;
}

.data-tile--value-cont.complex {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 3.2em;
  display: flex;
}

.data-tile--value.active {
  width: 2em;
  font-size: 2.6em;
}

.data-tile-target--value {
  width: 0;
  display: none;
}

.data-tile-target--value.active {
  width: auto;
  min-width: 1.5em;
  font-size: 1.6em;
  display: block;
}

.graph {
  --graph-total-height: 100%;
  --graph-height: 90%;
  --graph-heading-height: 10%;
  height: var(--graph-total-height);
  width: 100%;
  font-size: 1rem;
  position: relative;
}

#graph-power {
  overflow: hidden;
}

div.graph--bar-group {
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0;
  display: flex;
}

#graph--info--cont {
  color: var(--black);
  white-space: nowrap;
  background-color: var(--white);
  z-index: 1;
  border-radius: .2em;
  padding: .4em;
  font-size: 1.4em;
  line-height: 1.2em;
  display: none;
  position: absolute;
}

#workout-list svg.graph--bar-group {
  height: var(--graph-height);
  height: 8em;
}

.graph--heading {
  font-family: var(--ssb-font-family);
  text-align: center;
  height: var(--graph-heading-height);
  z-index: 1;
  font-size: 1em;
  font-weight: bold;
  position: relative;
}

.elevation--value {
  font-size: 1.6em;
  font-weight: bold;
  position: absolute;
  top: .4em;
}

.ascent--value {
  right: .3em;
}

.altitude--value {
  left: .3em;
}

.graph--msg {
  z-index: 1;
  font-size: 1em;
  line-height: 1.4em;
  position: relative;
}

.graph--cont {
  height: var(--graph-height);
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.graph--bar {
  justify-content: center;
  align-items: flex-start;
  width: 1px;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.graph--info {
  color: var(--black);
  background-color: var(--white);
  z-index: 1;
  border-radius: 2px;
  padding: 2px;
  display: none;
  position: absolute;
  bottom: 60%;
}

.graph--bar:hover .graph--info {
  display: block;
}

.graph--info--power, .graph--info--slope, .graph--info--cadence {
  min-width: 48px;
}

.graph--info--time {
  color: var(--gray);
}

.workout--graph-cont {
  height: var(--graph-height);
  flex-flow: row;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
  display: flex;
}

.workout-list--graph-cont {
  min-height: var(--list--graph--height);
  flex-flow: row;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
  display: flex;
  position: relative;
}

#progress {
  height: var(--graph-height);
  justify-content: start;
  align-items: flex-end;
  width: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

#progress-active {
  height: var(--graph-height);
  z-index: 1;
  justify-content: start;
  align-items: flex-end;
  width: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

#targets {
  --inner-max-width: 300px;
  --mode-selector-height: 3rem;
  --mode-selector-margin: .8em auto;
  --mode-selector-padding: 0 1em;
  --mode-width: 100%;
  --number-input-height: 3rem;
  --number-input-total-width: 100%;
  --number-input-value-width: 40%;
  --number-input-width: 30%;
  --number-input-font-size: 1.2em;
  --number-input-value-font-size: 1.4em;
  padding: 0 1em;
  font-size: 1rem;
}

.targets--cont {
  max-width: var(--inner-max-width);
  margin: 0 auto;
}

.mode-selector {
  height: var(--mode-selector-height);
  margin: var(--mode-selector-margin);
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
  font-size: 1em;
  display: flex;
}

.mode {
  width: var(--mode-width);
  height: var(--mode-selector-height);
  line-height: var(--mode-selector-height);
  padding: var(--mode-selector-padding);
  color: var(--gray);
  cursor: pointer;
}

.mode.active {
  font-weight: bold;
}

.number-input {
  justify-content: center;
  align-items: center;
  display: flex;
}

.number-input--value, .number-input--inc, .number-input--dec {
  height: var(--number-input-height);
}

.number-input--value {
  width: var(--number-input-value-width);
  line-height: var(--number-input-line-height);
  font-size: var(--number-input-value-font-size);
  text-align: center;
  color: var(--dark);
  border: 1px solid var(--white-opacity-01);
}

.number-input--btn {
  font-size: var(--number-input-font-size);
  width: var(--number-input-width);
  text-align: center;
}

.number-input--inc {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.number-input--dec {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fixed-bottom {
  --controls-height: 5em;
  --menu-height: 5em;
  --fb-total-height: 10em;
  --size--icon--global: 2.4em;
  --size--icon--menu--btn: 2em;
  height: var(--fb-total-height);
  background-color: var(--dark);
  z-index: 3;
  width: 100%;
  font-size: 1rem;
  position: fixed;
  bottom: 0;
}

.fixed-bottom--cont {
  height: var(--controls-height);
  border-top: 1px solid var(--white-opacity-01);
  z-index: 1;
  width: 100%;
}

.watch {
  text-align: right;
  width: 100%;
  padding: .5em 0;
  display: block;
}

.watch .btn {
  margin: 0 2px;
}

.control--btn {
  margin: 0 .8em;
  display: inline-block;
}

.control--btn--icon {
  width: var(--size--icon--global);
  height: var(--size--icon--global);
  fill: #fff;
  cursor: pointer;
}

#workout-name {
  font-size: 1.2em;
  font-family: var(--ssb-font-family);
  letter-spacing: 1px;
  text-transform: uppercase;
  float: left;
  text-align: left;
  max-width: 50%;
  margin: 0 .8em;
  font-weight: bold;
  display: block;
}

#watch-pause, #watch-lap, #watch-stop, #activity-save {
  display: none;
}

.tab.active {
  display: block;
}

.tab {
  display: none;
}

.menu {
  height: var(--menu-height);
}

.menu--row {
  wrap: wrap;
  z-index: 1;
  flex-direction: row;
  place-content: stretch center;
  align-items: center;
  height: 100%;
  display: flex;
}

.menu--btn {
  cursor: pointer;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.menu--btn--icon {
  width: var(--size--icon--menu--btn);
  height: var(--size--icon--menu-btn);
  fill: var(--gray);
  display: block;
}

.menu--btn--label {
  letter-spacing: 1px;
  color: var(--gray);
  font-size: 1em;
  line-height: 1.4em;
}

.menu--btn--icon--fill {
  fill: var(--gray);
}

.menu--btn.active .menu--btn--label {
  color: var(--white);
}

.menu--btn.active .menu--btn--icon--fill {
  fill: var(--white);
}

#workouts-page {
  --workout--name-font-size: 1.4em;
  --workout--type-font-size: 1.4em;
  --workout--duration-font-size: 1.4em;
  --workout--description-font-size: 1.4em;
  --inner-max-width: 47em;
  --size--icon--global: 2.4em;
  --list--graph--height: 8em;
  text-align: center;
  font-size: 1rem;
}

.workout {
  border-bottom: 1px solid var(--white-opacity-01);
  display: block;
  position: relative;
}

.workout--info {
  background-color: var(--dark);
  z-index: 1;
  padding: 0 1.5em;
  position: relative;
}

.options-show {
  transform: translateX(-8rem);
}

.options-hide {
  transform: translateX(0);
}

.workout--actions {
  background-color: var(--red);
  z-index: 0;
  width: 8em;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.workout--remove {
  cursor: pointer;
  place-self: center;
  font-size: 1.4em;
}

.workout--options {
  margin-left: 1em;
}

.workout--short-info {
  max-width: var(--inner-max-width);
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.workout--summary {
  text-align: left;
  cursor: pointer;
  flex-direction: row;
  place-content: center space-evenly;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 1.5em 0;
  display: flex;
}

.workout--name {
  font-size: var(--workout--name-font-size);
  width: 50%;
}

.workout--type {
  font-size: var(--workout--type-font-size);
  width: 20%;
  min-width: 100px;
  font-weight: bold;
}

.workout--duration {
  font-size: var(--workout--duration-font-size);
  color: var(--gray);
  width: 15%;
  min-width: 60px;
}

.workout--full-info {
  display: none;
}

.workout--description {
  font-size: var(--workout--description-font-size);
  max-width: var(--inner-max-width);
  text-align: left;
  margin: 0 auto;
  padding: 1.5em 0 .5em;
  line-height: 1.4em;
}

.radio {
  width: var(--size--icon--global);
  height: var(--size--icon--global);
}

.workout-loader--note {
  font-size: var(--workout--description-font-size);
  color: var(--gray);
  margin: 0 2em 2em;
  line-height: 1.4em;
}

#power-in-zone {
  max-width: var(--inner-max-width);
  grid-template-columns: repeat(8, 1fr);
  place-items: center;
  height: 4em;
  margin: 20px auto;
  padding: 0 1em;
  display: grid;
}

.power--zone--item {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.4em;
  font-weight: bold;
  display: flex;
  position: relative;
}

.power--unit {
  cursor: pointer;
}

.power--zone-bar {
  z-index: -1;
  height: 0;
  min-height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.laps--cont {
  margin: 2em auto 4em;
}

.lap--item {
  border-bottom: 1px solid var(--white-opacity-01);
}

.lap--item--inner {
  max-width: var(--inner-max-width);
  grid-template-columns: 2em repeat(4, 1fr);
  place-items: center;
  height: 4em;
  margin: 0 auto;
  padding: 0 1em;
  display: grid;
}

.lap--value {
  font-size: 1.2em;
}

.lap--power {
  font-weight: bold;
}

.library--switch--group {
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  display: grid;
}

.library--switch {
  cursor: pointer;
}

.library--switch--heading {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 1.1em;
  line-height: 5em;
}

.library--switch .library--switch--heading {
  color: var(--gray);
}

.library--switch.active .library--switch--heading {
  color: #fff;
}

#workouts {
  margin-top: 20px;
  display: none;
}

#ride-report, #workout-editor {
  display: none;
}

#workouts.active, #ride-report.active, #workout-editor.active {
  display: block;
}

.editor-cont {
  --editor-max-width: 48em;
  width: 100%;
  font-size: 1rem;
}

.editor--row, .editor--form--group {
  max-width: var(--editor-max-width);
  width: 100%;
  margin: 0 auto;
}

.editor--meta {
  background-color: var(--chrome);
  padding: .5em 0;
  display: grid;
}

.editor--form--group {
  align-items: center;
  display: grid;
}

.editor--input {
  color: #fff;
  background-color: var(--dark);
  background-color: var(--chrome);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--yellow);
  margin: 1em;
  font-size: 1.3em;
  line-height: 1.3em;
}

.editor--label {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 1em 1.3em 0;
  font-weight: bold;
  line-height: 1.3em;
}

.editor--label--textarea {
  align-self: start;
  margin-top: 1.5em;
}

.editor--head {
  background-color: var(--chrome);
  margin: 0 0 1em;
}

.editor--row {
  grid-template-columns: 10% repeat(6, 15%);
  display: grid;
}

.editor--th {
  cursor: default;
  padding: .5em;
  font-size: 1em;
  font-weight: bold;
}

.editor--td {
  text-align: center;
  background-color: var(--dark);
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5em;
  font-size: 1.3em;
  line-height: 1.3em;
  display: flex;
}

.editor--td.power, .editor--td.ramp {
  font-size: 1.4em;
  font-weight: bold;
}

.editor--header {
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: var(--chrome);
  padding: 1.5em 1em 1em;
  font-size: 1em;
  font-weight: bold;
}

.editor--static {
  cursor: default;
}

.editor--icon {
  cursor: pointer;
  margin: 1em 2em;
}

.editor--save {
  fill: var(--gray);
  cursor: not-allowed;
}

.editor--radio {
  cursor: pointer;
  width: 1em;
  height: 1em;
  display: none;
}

.editor--radio.selected {
  display: block;
}

#settings-page {
  --inner-max-width-fixed: 480px;
  --inner-max-width: 48em;
  --inner-max-wider: 60em;
  --size--icon--global: 2.4em;
  font-size: 1rem;
  line-height: 1.4em;
}

.settings-tiles--cont {
  max-width: var(--inner-max-width);
  --width--settings-tile: 12em;
  --height--settings-tile: 12em;
  --font-size--label: 1.2em;
  --font-size--input: 3em;
  --height--label: 3em;
  --height--input: 1em;
  margin: 0 auto;
  display: flex;
}

.settings-tile {
  width: var(--width--settings-tile);
  height: var(--height--settings-tile);
  text-align: center;
  border: 1px solid var(--white-opacity-01);
  border-radius: 50%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  padding: 5px;
  display: grid;
}

.settings-tile--label {
  font-size: var(--font-size--label);
  text-transform: uppercase;
  height: var(--height--label);
  font-weight: bold;
  line-height: var(--height--label);
  display: block;
}

.settings-tile--input {
  font-size: var(--font-size--input);
  height: var(--height--input);
  text-align: center;
  color: var(--white);
  background-color: var(--dark);
  border: none;
  width: 100%;
  font-weight: bold;
}

#ftp-value {
  color: var(--yellow);
}

.settings-tile--btn {
  font-size: var(--font-size--label);
  height: var(--height--label);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  padding: 0 14px;
  font-weight: bold;
}

.settings-btn-row {
  max-width: var(--inner-max-width);
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  display: flex;
}

.settings-switch-btn-cont {
  margin: 10px auto;
  font-size: 1em;
}

.settings-switch-bnt {
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 4em;
  font-weight: bold;
  display: flex;
}

.row-border, .border-top {
  border-top: 1px solid var(--white-opacity-01);
}

.device-card {
  --settings-row-max-width: 60em;
  --font-size-value: 1.2em;
  --line-height--device-card--tile: 1.2em;
  --grid-template-columns--device-card--body--row: 5em 2fr 1fr 1.1fr 1fr 1fr;
  --size--icon: 2.7em;
  border-bottom: 1px solid var(--white-opacity-01);
}

.device-card--cont {
  max-width: var(--settings-row-max-width);
  margin: 0 auto;
  padding: .2em 0 .8em;
}

.device-card--header--label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.1em;
  line-height: 3em;
}

.device-card--body--row {
  grid-template-columns: var(--grid-template-columns--device-card--body--row);
  padding: 0 5px 0 0;
  display: grid;
}

.ant-not-supported {
  display: none !important;
}

.device-card--body--row.second {
  margin-top: 1.5em;
}

.device-card--tile {
  text-align: left;
}

.device-card--tile--header {
  font-size: var(--font-size--device-card--tile--header);
  margin-bottom: 4px;
  line-height: 1em;
}

.device-card--tile--value {
  font-size: var(--font-size-value);
  font-weight: bold;
}

.simulation {
  --settings-row-max-width: 60em;
  margin: 0 auto 4em;
}

.simulation--cont {
  max-width: var(--settings-row-max-width);
  margin: 0 auto;
}

.simulation-header--label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.1em;
  line-height: 3em;
}

.simulation--switch {
  text-transform: uppercase;
  cursor: pointer;
  margin: 1em auto;
  font-weight: bold;
  display: block;
}

.sound {
  --settings-row-max-width: 60em;
  margin: 4em auto;
}

.sound--cont {
  max-width: var(--settings-row-max-width);
  margin: 0 auto;
  padding: 0 0;
}

.sound-header--label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.1em;
  line-height: 3em;
}

.sound--row {
  vertical-align: baseline;
  max-width: var(--inner-max-width);
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.sound--row--item {
  width: 7rem;
  max-width: 25%;
  height: 3rem;
  margin: 1rem 0;
  line-height: 3rem;
}

.sound--control {
  cursor: pointer;
}

.sound--volume {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
}

.connection-icon-switch--icon {
  fill: #fff;
  width: var(--size--icon);
  height: var(--size--icon);
}

.connection-icon-switch--icon.disabled {
  fill: var(--gray);
}

.connection-icon-switch--indicator {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 5px auto;
}

.source-switch {
  color: var(--gray);
  cursor: pointer;
  display: block;
}

.source-switch.active {
  color: #fff;
}

#ant-device-request {
  text-align: center;
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#ant-device-request.active {
  display: block;
}

.device-request--wrapper {
  --grid-template-columns--device-request--list: 1fr 1fr 1fr;
  background-color: var(--chrome);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 48em;
  max-width: 100%;
  font-size: 1rem;
  display: inline-block;
  box-shadow: 1px 2px 4px #0003;
}

.device-request--list {
  min-height: 100px;
}

.device-request--item {
  grid-template-columns: var(--grid-template-columns--device-request--list);
  cursor: pointer;
  align-items: center;
  height: 3em;
  display: grid;
}

.device-request--item.active {
  background-color: var(--black-opacity-01);
}

.device-request--item--protocol {
  font-size: 1.2em;
}

.device-request--item--number {
  font-size: 1.2em;
  font-weight: bold;
}

.device-request--item--type {
  font-size: 1.2em;
}

.device-request--controls {
  border-top: 1px solid var(--black-opacity-01);
  grid-template-columns: 60px 1fr 11em 11em;
  align-items: center;
  padding: 1em;
  display: grid;
}

.device-request--status {
  justify-self: flex-start;
}

.device-request--controls button {
  text-transform: uppercase;
  font-size: 1em;
  font-family: var(--ss-font-family);
  color: #fff;
  background-color: var(--chrome);
  border: 1px solid var(--white-opacity-01);
  cursor: pointer;
  border-radius: 3px;
  justify-self: flex-end;
  width: 9em;
  height: 3em;
  margin: 0 1em;
}

.app-connections {
  margin: 1.4em auto;
}

.app-connection {
  max-width: var(--inner-max-width);
  align-items: center;
  margin: 0 auto;
  padding: 1em;
  display: flex;
}

.app-connection--icon {
  width: 4em;
  height: 4em;
  margin-right: 1em;
}

.app-connection--heading {
  text-align: left;
  text-transform: uppercase;
  font-size: 1em;
}

#about {
  max-width: var(--inner-max-width);
  text-align: center;
  margin: 10px auto 0;
  padding: 10px;
  font-size: 1em;
}

.about--text {
  margin-bottom: 3em;
  font-size: 1.2em;
  line-height: 1.4em;
}

.about--version, .about--links {
  margin: .5em auto;
}

.license {
  letter-spacing: 1px;
}

@media (width <= 340px) and (height <= 620px) {
  #home-page-wrapper {
    grid-template-rows: 40px 2fr 1fr 1fr 70px 80px;
  }

  .data-tiles {
    --data-tile--heading-margin: 0 auto .4em;
  }

  .mode-selector {
    margin: 1em auto;
  }

  .fixed-bottom {
    --controls-height: 4em;
    --menu-height: 4em;
    --fb-total-height: 8em;
  }
}

@media (width >= 414px) and (height >= 730px) {
  .data-tiles {
    font-size: 1.2rem;
  }
}

@media (width >= 600px) and (height >= 600px) {
  .data-tiles {
    grid-template-rows: 50% 50%;
    grid-template-columns: repeat(5, 20%);
    place-items: center;
    display: grid;
  }

  #data-tile--power-avg {
    grid-area: 1 / 1 / 1 / 1;
    display: block;
  }

  #data-tile--power {
    grid-area: 1 / 2 / 1 / 2;
  }

  #data-tile--interval-time {
    grid-area: 1 / 3 / 1 / 3;
  }

  #data-tile--heart-rate {
    grid-area: 1 / 4 / 1 / 4;
  }

  #data-tile--slope {
    grid-area: 2 / 1 / 2 / 1;
    display: block;
  }

  #data-tile--target {
    grid-area: 2 / 2 / 2 / 2;
  }

  #data-tile--elapsed-time {
    grid-area: 2 / 3 / 2 / 3;
  }

  #data-tile--cadence {
    grid-area: 2 / 4 / 2 / 4;
  }

  #data-tile--speed {
    grid-area: 1 / 5 / 1 / 5;
  }

  #data-tile--distance {
    grid-area: 2 / 5 / 2 / 5;
  }

  #data-tile--smo2.active {
    grid-area: 3 / 1 / 3 / 4;
    display: none;
  }

  #data-tile--thb.active {
    grid-area: 3 / 4 / 3 / 7;
    display: none;
  }

  .data-tile-small--unit, .data-tile--switch--group {
    display: none;
  }

  #data-tile--power-avg, #data-tile--slope, #data-tile--speed, #data-tile--distance {
    display: block;
  }

  .device-card {
    --grid-template-columns--device-card--body--row: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (width >= 768px) and (height >= 1024px) and (orientation: portrait) {
  #home-page-wrapper {
    grid-template-rows: 50px 2.5fr 1fr 1fr 108px 100px;
  }

  .data-tiles {
    --data-tile-small--heading-font-size: .8em;
    --data-tile-small--value-font-size: 2em;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(12, 1fr);
    font-size: 2rem;
    display: grid;
  }

  #data-tile--power-avg {
    grid-area: 3 / 1 / 3 / 4;
    display: block;
  }

  #data-tile--power {
    grid-area: 1 / 1 / 1 / 5;
  }

  #data-tile--interval-time {
    grid-area: 1 / 5 / 1 / 9;
  }

  #data-tile--heart-rate {
    grid-area: 1 / 9 / 1 / 13;
  }

  #data-tile--slope {
    grid-area: 3 / 4 / 3 / 7;
    display: block;
  }

  #data-tile--target {
    grid-area: 2 / 1 / 2 / 5;
  }

  #data-tile--elapsed-time {
    grid-area: 2 / 5 / 2 / 9;
  }

  #data-tile--cadence {
    grid-area: 2 / 9 / 2 / 13;
  }

  #data-tile--speed {
    grid-area: 3 / 7 / 3 / 10;
  }

  #data-tile--distance {
    grid-area: 3 / 10 / 3 / 13;
  }

  .data-tile-small--unit {
    display: none;
  }

  .graph--heading {
    font-size: 1.2em;
    line-height: 2em;
  }

  #graph--info--cont {
    font-size: 1.6em;
  }

  #targets {
    --number-input-height: 4rem;
    --mode-selector-margin: 1.7em auto;
  }

  .data-tile--switch--group {
    display: none;
  }

  #data-tile--power-avg, #data-tile--slope, #data-tile--speed, #data-tile--distance {
    display: block;
  }
}

@media only screen and (width >= 1024px) and (height >= 768px) {
  #home-page-wrapper {
    grid-template-rows: 50px 2fr 1fr 1fr 98px 100px;
  }

  .data-tiles {
    font-size: 1.6rem;
  }

  #graph--info--cont {
    font-size: 1.6em;
  }
}

@media (width >= 1024px) and (height >= 1366px) {
  #home-page-wrapper {
    grid-template-rows: 50px 2.5fr 1fr 1fr 108px 100px;
  }

  .data-tiles {
    font-size: 2.7rem;
  }

  .graph--heading {
    font-size: 1.2em;
    line-height: 2em;
  }

  #targets {
    --number-input-height: 4rem;
    --mode-selector-margin: 1.7em auto;
  }
}

@media only screen and (width >= 1360px) and (height >= 1020px) and (orientation: landscape) {
  .data-tiles {
    font-size: 2.7rem;
  }
}

@media only screen and (width >= 1200px) and (height >= 600px) {
  .data-tiles {
    --data-tile--heading-font-size: .8em;
    --data-tile-small--heading-font-size: .8em;
    --data-tile--heading-margin: 0 auto .5em;
    font-size: 2rem;
  }
}

@media only screen and (width >= 1360px) and (height >= 700px) {
  .data-tiles {
    font-size: 2.4rem;
  }
}

@media only screen and (width >= 1600px) and (height >= 800px) {
  .data-tiles {
    font-size: 2.6rem;
  }

  #settings-page {
    font-size: 1.1rem;
  }
}

@media only screen and (width >= 1800px) and (height >= 850px) {
  #home-page-wrapper {
    grid-template-rows: 5em 2.5fr 1fr 1fr 14em 14em;
  }

  .connections-header {
    font-size: 1.2rem;
  }

  .data-tiles {
    font-size: 3rem;
  }

  #graph--info--cont {
    font-size: 2em;
  }

  #targets {
    --number-input-height: 5rem;
    --mode-selector-margin: 2em auto;
    --inner-max-width: 50em;
  }

  .watch {
    padding: 1em;
  }

  #settings-page, .editor-cont {
    font-size: 1.2rem;
  }

  .fixed-bottom {
    font-size: 1.4rem;
  }
}

@media only screen and (width >= 1900px) and (height >= 900px) {
  .data-tiles {
    font-size: 3.2rem;
  }
}

@media only screen and (width >= 2400px) and (height >= 1200px) {
  #home-page-wrapper {
    grid-template-rows: 6em 2.5fr 1fr 1fr 16em 16em;
  }

  .connections-header {
    font-size: 1.6rem;
  }

  .switch--label {
    font-size: 1.5rem;
  }

  .data-tiles {
    font-size: 4.5rem;
  }

  .graph--heading {
    font-size: 1.8em;
    line-height: 2.5em;
  }

  .workout--short-info {
    line-height: 1.5em;
  }

  #graph--info--cont {
    font-size: 2.4em;
  }

  #targets {
    --number-input-height: 6rem;
    --mode-selector-margin: 2em auto;
    --inner-max-width: 50em;
    --mode-selector-padding: 0 1em;
    font-size: 1.4rem;
  }

  .fixed-bottom {
    font-size: 1.6rem;
  }

  #workout-name {
    line-height: 2em;
  }

  #settings-page, #workouts-page, .editor-cont {
    font-size: 1.6rem;
  }
}

@media only screen and (width >= 3000px) and (height >= 1600px) {
  #home-page-wrapper {
    grid-template-rows: 10em 2.5fr 1fr 1fr 24em 24em;
  }

  .data-tiles {
    font-size: 6.4rem;
  }

  .connections-header {
    font-size: 2.4rem;
  }

  #graph--info--cont {
    font-size: 4em;
  }

  #targets {
    --number-input-height: 8rem;
    --mode-selector-margin: 2em auto;
    --inner-max-width: 44em;
    --mode-selector-padding: 0 1em;
    font-size: 2.4rem;
  }

  #settings-page, #workouts-page, .editor-cont, .fixed-bottom {
    font-size: 2.4rem;
  }
}

@media (height <= 428px) and (width >= 500px) {
  #home-page-wrapper {
    grid-template-rows: 1.5fr 1fr 1fr 50px;
    grid-template-columns: 50px 1fr 1fr;
    display: grid;
  }

  page-group {
    padding-right: 100px;
    display: block;
  }

  #home-page .page-header {
    flex-direction: column;
    grid-area: 1 / 1 / -1 / 1;
  }

  .connection-switch--indicator {
    margin: 0;
  }

  .connection-switch--label {
    display: none;
  }

  .data-tiles {
    --data-tile--heading-margin: 0 auto .3em;
    grid-area: 1 / 2 / 2 / 4;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr 1fr 1.2fr 1fr 1fr;
    justify-content: center;
    place-items: center;
    font-size: 1rem;
    display: grid;
  }

  #data-tile--power-avg {
    grid-area: 1 / 1 / 1 / 1;
    display: block;
  }

  #data-tile--power {
    grid-area: 1 / 2 / 1 / 2;
  }

  #data-tile--interval-time {
    grid-area: 1 / 3 / 1 / 3;
  }

  #data-tile--heart-rate {
    grid-area: 1 / 4 / 1 / 4;
  }

  #data-tile--slope {
    grid-area: 2 / 1 / 2 / 1;
    display: block;
  }

  #data-tile--target {
    grid-area: 2 / 2 / 2 / 2;
  }

  #data-tile--elapsed-time {
    grid-area: 2 / 3 / 2 / 3;
  }

  #data-tile--cadence {
    grid-area: 2 / 4 / 2 / 4;
  }

  #data-tile--speed {
    grid-area: 1 / 5 / 1 / 5;
  }

  #data-tile--distance {
    grid-area: 2 / 5 / 2 / 5;
  }

  #graph-power {
    grid-area: 2 / 2 / 3 / 4;
  }

  #graph-workout {
    grid-area: 3 / 2 / 3 / 4;
  }

  #targets {
    --mode-selector-margin: 1em auto;
    --mode-selector-padding: 0 1em;
    grid-area: 4 / 2 / 4 / 4;
    padding: 0 0;
  }

  .targets--cont {
    justify-content: center;
    place-items: center;
    max-width: none;
    min-height: 100%;
    display: flex;
  }

  .tab-btn-group {
    width: 50%;
    padding: 0 0 0 1em;
  }

  tab-group {
    width: 50%;
    padding: 0 1em 0 0;
  }

  .fixed-bottom {
    --controls-height: 100vh;
    --menu-height: 100vh;
    --fb-total-height: 100vh;
    grid-area: 1 / 4 / 3 / 5;
    width: 100px;
    display: flex;
    top: 0;
    right: 0;
  }

  .controls {
    border-top: none;
    width: 40%;
  }

  .dark-theme .controls {
    background-color: var(--chrome);
  }

  .menu {
    background-color: var(--chrome);
    width: 60%;
  }

  .watch {
    flex-direction: column;
    place-content: center space-between;
    align-items: center;
    height: 100vh;
    padding: 0;
    display: flex;
  }

  .menu--row {
    flex-direction: column;
    place-content: center space-between;
    align-items: center;
    height: 100vh;
  }

  #workout-name, .data-tile--switch--group {
    display: none;
  }
}

@media (height <= 300px) and (width >= 1024px) {
  #home-page-wrapper {
    grid-template-rows: 1fr 1fr 4em;
    grid-template-columns: 1fr 2fr 1fr;
    display: grid;
  }

  page-group {
    padding-right: 12em;
    display: block;
  }

  #home-page .page-header {
    flex-direction: row;
    grid-area: 3 / 1 / 3 / 1;
  }

  .switch--indicator {
    margin: 0;
  }

  .switch--label {
    display: none;
  }

  .connection-switch--indicator {
    margin: 0 1em 0 0;
  }

  .connection-switch--label {
    display: block;
  }

  .line {
    display: none;
  }

  .data-tiles {
    --data-tile--heading-margin: 0 auto .5em;
    --data-tile--heading-font-size: .8em;
    --data-tile-small--heading-font-size: .8em;
    background-color: var(--chrome);
    grid-area: 1 / 2 / -1 / 3;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    display: grid;
  }

  #data-tile--power-avg {
    grid-area: 1 / 1 / 1 / 2;
    display: block;
  }

  #data-tile--power {
    grid-area: 1 / 2 / 1 / 2;
  }

  #data-tile--interval-time {
    grid-area: 1 / 3 / 1 / 3;
  }

  #data-tile--heart-rate {
    grid-area: 1 / 4 / 1 / 4;
  }

  #data-tile--slope {
    grid-area: 2 / 1 / 2 / 1;
    display: block;
  }

  #data-tile--target {
    grid-area: 2 / 2 / 2 / 2;
  }

  #data-tile--elapsed-time {
    grid-area: 2 / 3 / 2 / 3;
  }

  #data-tile--cadence {
    grid-area: 2 / 4 / 2 / 4;
  }

  #data-tile--speed {
    grid-area: 1 / 5 / 1 / 6;
    display: block;
  }

  #data-tile--distance {
    grid-area: 2 / 5 / 2 / 6;
    display: block;
  }

  .data-tile-small--unit {
    display: none;
  }

  #graph-workout {
    grid-area: 1 / 1 / 3 / 2;
  }

  #graph-power {
    grid-area: 1 / 3 / 3 / 4;
    display: block;
  }

  #targets {
    --mode-selector-padding: 0 1em;
    --mode-selector-margin: 1em auto;
    background-color: var(--chrome);
    grid-area: 3 / 3 / 3 / 3;
  }

  .targets--cont {
    grid-template-colums: 1fr 1fr;
    grid-template-rows: 1fr;
    display: grid;
  }

  tab-btn-group {
    grid-area: 1 / 1 / 1 / 1;
  }

  tab-group {
    grid-area: 1 / 2 / 1 / 2;
    width: auto;
    padding: 0;
  }

  .fixed-bottom {
    --controls-height: 100vh;
    --menu-height: 100vh;
    --fb-total-height: 100vh;
    width: 12em;
    display: flex;
    top: 0;
    right: 0;
  }

  .controls {
    width: 50%;
  }

  .dark-theme .controls {
    background-color: var(--chrome);
  }

  .menu {
    background-color: var(--chrome);
    width: 50%;
  }

  .watch {
    height: var(--controls-height);
    flex-direction: column;
    place-content: center space-between;
    align-items: center;
    padding: 0;
    display: flex;
  }

  .menu--row {
    height: var(--menu-height);
    flex-direction: column;
    place-content: center space-between;
    align-items: center;
  }

  #workout-name {
    display: none;
  }

  .workout-list--graph-cont {
    float: left;
    width: 50%;
  }

  .workout--description {
    float: right;
    width: 50%;
    padding: 1em 1em 0;
  }
}

@media only screen and (height <= 300px) and (width >= 1200px) {
  .data-tiles {
    grid-template-columns: 19% 19% 24% 19% 19%;
  }

  #data-tile--speed, #data-tile--speed.active, #data-tile--distance, #data-tile--distance.active {
    display: block;
  }
}

@media only screen and (height <= 300px) and (width >= 1600px) {
  .data-tiles {
    --data-tile--heading-margin: 0 auto 0em;
    font-size: 1.3rem;
  }
}

@media only screen and (height <= 300px) and (width >= 1900px) {
  .data-tiles {
    --data-tile--heading-font-size: .8em;
    --data-tile-small--heading-font-size: .8em;
    font-size: 1.6rem;
  }
}

/*# sourceMappingURL=index.fc6a0594.css.map */
